import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Student, imgPlaceholder } from "../Interfaces";
import axios from "axios";
import RecordPayment from "./Record-payment";
import EditStudent from "./Edit";
import Template from "../Template";
import { edit_payment, permitted_everywhere, record_payment, register_student } from "../../Permissions";
import { useAuth } from "../../AuthContext";
import EditPayment from "./Edit-payment";
import { api, multipartApi } from "../../utils/customizeAxios";
import { Selector } from "../class/Students";
import { notDeleted } from "../../utils/stringifyGrade";
import CustomComment from "./customComment";
const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

export default function StudentProfile(){
    return(<Template children={<Page />} />);
}
function Page(){
    const [student, setStudent] = useState<Student>({name: ''});
    const [repull, setRepull] = useState<boolean>(false);
    const {id} = useParams();
    const {authUser} = useAuth();

    useEffect(() => {
        axios.get(apiUrl + '/student/' + id)
        .then(r => {
            console.log(r.data);
            setStudent(r.data);
        }).catch(e => console.error(e));
    }, [repull]);

    const handleRestore = (id: string) => {
        if(!window.confirm('Are you sure?')) return
        api.patch('/students/restore/'+ id)
        .then( () => {
            alert('Restored succesfuly')
            setRepull(!repull)
        })
        .catch(e => {
            if(e.response.data)
                alert(e.response.data.message)
            else
                alert("Failed to restore, please check your network")
        })
    }
    
    console.log(student.periods)
    return(
        <div className="m-2">
            <div className="md:lg:flex default-container">
                <div className="w-40 h-40 ">
                    {student.profile_pic_filepath === null ? 
                        imgPlaceholder
                        :
                        <img src={`${imgUrl}/${student.profile_pic_filepath}`} 
                            className="w-full h-full rounded-full mr-1" 
                            alt="photo" 
                        />
                    }
                </div>
                <div className="ml-4 pl-4">
                    <p className="font-bold text-xl">{student.name}</p>
                    <p className="font-light">{student.class?.name}</p>
                    <p className="font-bold text-sm text-purple-700">{student.section}</p>
                    <p className="font-light text-xl">{student.sex?.toLowerCase() == 'm' ? 'Male' : 'Female'}</p>
                    {authUser?.role && record_payment.includes(authUser?.role) &&
                        <p><b>Schoolfees Balance:</b> {student.balance}</p>
                    }
                </div>
                {authUser?.role && register_student.includes(authUser.role) ?
                notDeleted(student) ?
                    <EditStudent stdnt={student} repull={repull} setRepull={setRepull}/>
                    :
                    <button className="inputstyle" onClick={() => handleRestore(String(student.id))}>Restore</button>
                :
                <div></div>
                }
            </div>
                    {authUser?.role && record_payment.includes(authUser.role) && student.periods &&
                        <div className="rounded-md p-2 bg-purple-100">
                            <p className="text-center text-lg text-slate-500">Termly balance</p>
                            {student.periods.map(period => period.id && student.id &&
                                <div className="flex justify-between border-b-[2px]">
                                    <p>{period.name} - {period.date_from?.split('-')[0]}</p>
                                    <p>{student.balance_objs?.find(balanceObj => balanceObj.period_id === period.id)?.balance || (student.section === 'Day' ? student.class?.fees_day : student.class?.fees_boarding)}</p>
                                    {notDeleted(student) && <ChangeBalance periodId={period.id} studentId={student.id} repull={repull} setrepull={setRepull}/>}
                                </div>
                            )}
                        </div>
                    }
                    { (authUser?.class?.id === student.class_id) &&
                        <CustomComment studentId={student.id || 0} currentComment={student.custom_ct_comm || ""} />
                    }
                {notDeleted(student) && 
                <div className="p-2 md:lg:flex justify-around">
                    {register_student.includes(authUser?.role!) && 
                        <Selector student={student} />
                    }

                    {authUser?.role && record_payment.includes(authUser.role) &&
                        <EditPicture setRepull={setRepull} repull={repull} stdnt={student} />
                    }

                    {authUser?.role && record_payment.includes(authUser.role) &&
                        <RecordPayment student={student} repull={repull} setRepull={setRepull}/>
                    }

                </div>}
            <div className="mt-2">
                <h1 className="text-center text-purple-800">PARENT</h1>
                {student.parent && 
                        <Link to={'/user/' + student.parent.id}>
                        <div className="inputstyle bg-blue-300 flex justify-between text-lg font-bold">
                        {student.parent.name}
                        <div className="w-14 h-14">
                            {student.parent.profile_pic_filepath === null ? 
                                imgPlaceholder
                                :
                                <img src={`${imgUrl}/${student.parent.profile_pic_filepath}`} 
                                    className="w-full h-full rounded-full mr-1" 
                                    alt="photo" 
                                />
                            }
                        </div>
                        </div>
                        </Link>
                }
            </div>

            {authUser?.role && record_payment.includes(authUser.role) &&
            <div className="mt-4">
                <h1 className="text-center text-purple-800">PAYMENTS</h1>

                <table className="w-full">
                    <thead>
                        <tr className="backdrop-blur-md text-left bg-purple-300">
                            <th className="p-3">Amount</th>
                            <th>Term</th>
                            <th>Balance</th>
                            <th>Date paid</th>
                            <th>Payment method</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {student.payments?.map(payment =>
                        <tr className="border-b-2 border-purple-400 hover:bg-purple-200">
                            <td className="p-1">{payment.amount}</td>
                            <td>{student.periods?.find(period => period.id === payment.period_id)?.name}</td>
                            <td>{payment.balance}</td>
                            <td>{payment.date_paid}</td>
                            <td>{payment.payement_method}</td>
                            <td>{authUser.role && edit_payment.includes(authUser.role) && notDeleted(student) &&  <EditPayment student={student} repull={repull} setRepull={setRepull} payment={payment} />}</td>
                            <Link to={'/reciept/' + payment.id}>
                                <td><button className="inputstyle hover:bg-purple-500">Reciept</button> </td>
                            </Link>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            }
        </div>
    );
}

function ChangeBalance({periodId, studentId, repull, setrepull}: {studentId: number, periodId: number, repull: boolean, setrepull: any}){
    const [amount, setAmount] = useState<string>();
    const [hidden, setHidden] = useState(true);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios.post(`${apiUrl}/balance/${studentId}/${periodId}`, {amount: amount})
        .then(r => {
            alert(r.data.message);
            setHidden(true);
            setrepull(!repull);
        }).catch(e => {
            alert(e.data.error);     
        });
    }

    return(
        <div>
            <button onClick={() => setHidden(!hidden)} className="inputstyle">
                Edit
            </button>
            <form className="" hidden={hidden} onSubmit={e => handleSubmit(e)}>
            <p className="error-msg">The balance on previous payments won't change</p>
                <input className="inputstyle" type="number" name="amount" value={amount} onChange={e => setAmount(e.target.value)} />
                <input className="inputstyle" type="submit" value="CHANGE" />
            </form>
        </div>
    )
}


function EditPicture({stdnt, repull, setRepull}: {stdnt: Student, repull: boolean, setRepull: any}) {
    const [picture, setPicture] = useState<File>();
    const [hidden, setHidden] = useState<boolean>(true);
    const [msg, setMsg] = useState<string>('');

    const handlePictureSubmit = (e: React.FormEvent<Element>) => {
        e.preventDefault();
        if (!picture){
            alert('No picture chosen')
            return;
        }
        setMsg('');

        const formData = new FormData()
        formData.append('pictureFile', picture);

        multipartApi.post(`/student/photo/${stdnt.id}`, formData)
        .then(response => {
            console.log(response.data);
            setRepull(!repull);
            setHidden(true);
        })
        .catch(error => {
            if(error.response.data)
                alert(error.response.data.message);
            else
                alert("Failed to update picture: Unknown error");
            console.log(error);
        });

    };

    return(
    <div className="m-4 p-4">
    <button className="inputstyle" onClick={() => setHidden(!hidden)}>Edit Picture</button>
        <form className="mx-auto" hidden={hidden} onSubmit={e => handlePictureSubmit(e)}>
            <div className="font-light text-purple-700 text-center">{msg}</div>

            <div className="mt-4 flex flex-col">
                <label htmlFor="picture">Picture:</label>
                <input className="inputstyle" type="file" name="picture" onChange={e => e.target.files && setPicture(e.target.files[0])}/>
            </div>

            <input type="submit" name="submit" value='Edit' className="inputstyle bg-purple-700 ring-1 ring-purple-700 mt-4 text-white" />
        </form>
    </div>
    )
}