import Template from "../../Template";
import logo from "../../../images/logo.jpeg";
import { useEffect, useState } from "react";
import { AtomicMarkStats, Comment, Period, Requirement, Student, imgUrl, Grading, upperPrimary, Divisioning } from "../../Interfaces";
import { useParams } from "react-router-dom";
import { api } from "../../../utils/customizeAxios";
import stringifyAgg from "../../../utils/stringifyGrade";

const avatarIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                    </svg>

export default function ReportCard(){
    return (
        <Template children={<Page />} />
    )
}

export interface Payload {
    student: Student,
    midMarkData: AtomicMarkStats,
    endMarkData: AtomicMarkStats,
    term: Period,
    comments?: Comment,
    classteacher: string,
    headteacher: string,
    nxtTerm?: Period,
    requirements?: Requirement[],
    gradings?: Grading[],
    positionMid: {position: number, outof: number},
    positionEnd: {position: number, outof: number},
}

function Page(){
    const [payload, setPayload] = useState<Payload>();
    const [divisions, setDivisions] = useState<[Divisioning]>();
    const { id, periodId } = useParams();
    const studentsClass = payload?.student.class

    // going to use this to replace next term begins at incase the current term is third term
    const thisYear = new Date().getFullYear();
    const nextYear = thisYear + 1

    const fetchPayload = () => {
        api.get(`/student/reportcard/${id}/${periodId}`)
        .then(r => {
            console.log(r.data);
            setPayload(r.data);
        }).catch(e => {
            console.error(e);
        });

        api.get('/divisions/10')
        .then(r => {
            console.log(r.data);
            setDivisions(r.data);
        }).catch(e => {
            console.error(e);
        });
    };

    useEffect(() => {
        fetchPayload();
    }, [id, periodId])

    return(
        <section className="p-4 px-12 text-sm font-arial">
            <section className="bg-white/80 text-sm">
            <div className="flex justify-center">
                <img src={logo} alt="school badge" className="h-28" />
            </div>
            <div className="text-center border-b-2 pb-2 border-slate-500">
                <h3 className="text-2xl font-britannic-bold">SEETA CHURCH OF UGANDA PRIMARY SCHOOL</h3>
                <p className="">Email: seetacups@gmail.com Tel: 0782365689</p>

                {/*<h3 className="text-2xl font-britannic-bold">GRACE NURSERY &amp; PRIMARY SCHOOL</h3>
                <p className="">Email: gnaps2024@gmail.com Tel: 0702954787</p>*/}
            </div>

            <div className="flex w-full">
                <div className="text-center w-full mr-4 mt-1 p-2">
                    <h3 className="mb-4 font-arial">LEARNER'S END OF TERM {termT(payload?.term.name?.toLocaleUpperCase())} ACADEMIC REPORT CARD, {payload?.term.date_from?.split('-')[0]}</h3>
                    <table className="w-full">
                        <tr>
                            <th className="w-20">NAME: </th>
                            <td className="text-left text-blue-900 border-blue-900 border-b-2 w-1/2">{payload?.student.name.toLocaleUpperCase()}</td>
                            <th className="w-20">CLASS: </th>
                            <td className="text-left border-black border-b-2">{payload?.student.class?.name}</td>
                        </tr>
                    </table>
                </div>
                <div className="border-2 rounded-2xl border-black h-24 ml-2">
                    {payload?.student.profile_pic_filepath ? 
                        <img src={`${imgUrl}/${payload?.student.profile_pic_filepath}`} className="h-full w-full rounded-2xl" />
                    :
                        avatarIcon
                    }
                </div>
            </div>

            <div className="">
                <h1 className="text-center font-britannic-bold">END OF TERM RESULTS</h1>

                <table className="min-w-full table-auto border-collapse font-light">
                    <thead>
                        <tr className="border-2 text-left bg-slate-50">
                            <th className="px-2 border-2 border-black">SUBJECT</th>
                            <th className="border-2 border-black">TOTAL MARK</th>
                            <th className="border-2 border-black">{studentsClass && upperPrimary.includes(studentsClass?.name) ? 'GRADE' : 'MARK' }</th>
                            <th className="border-2 border-black">REMARKS</th>
                            <th className="border-2 border-black">TEACHER</th>
                        </tr>
                    </thead>
                    <tbody>
                    {payload?.endMarkData.subdata.map(stat => 
                        <tr key={stat.subjectId} className="border-2 border-purple-400 hover:bg-purple-200 text-sm">
                            <td className="py-0 border-2 border-black text-sm">{stat.name.toUpperCase()}</td>
                            <td className="border-2 py-0 border-black font-thin"> {studentsClass && upperPrimary.includes(studentsClass?.name) ? stat.mark : getTtMk(stat.name) }</td>
                            <td className="border-2 py-0 border-black font-thin text-red-900">{studentsClass && upperPrimary.includes(studentsClass?.name) ? stringifyAgg(stat.agg) : stat.mark }</td>
                            <td className="border-2 py-0 border-black font-thin">{stat.remark}</td>
                            <td className="border-2 py-0 border-black font-thin text-blue-900">{ stat.teacher?.split(' ').map(n=>n.charAt(0)) }</td>
                        </tr>
                    )}
                    {studentsClass && upperPrimary.includes(studentsClass?.name) ? 
                    <tr className="border-2 border-black font-extrabold">
                        <td className="py-0" colSpan={3}>
                            AGGREGATES: {payload?.endMarkData.totalAgg}
                        </td>
                        <td colSpan={2}>
                            DIVISION: {payload?.endMarkData.division}
                        </td>
                    </tr>
                    : 
                    <tr className="border-2 border-black font-extrabold">
                        <td className="p-0">
                            TOTAL: {payload?.endMarkData.totalMarks}
                        </td>
                        <td className="p-0">
                            POSITION: {payload?.positionEnd.position}
                        </td>
                        <td colSpan={3}>
                            OUT OF: {payload?.positionEnd.outof}
                        </td>
                    </tr>
                    }
                    </tbody>
                </table>
            </div>
{
            <div className="mt-2">
                <h1 className="text-center font-britannic-bold">MID TERM RESULTS</h1>

                <table className="min-w-full table-auto border-collapse" >
                    <thead>
                        <tr className="text-left border-2 border-black bg-slate-50">
                            <th className="px-3 border-2 border-black ">SUBJECT</th>
                            <th className="border-2 border-black">TOTAL MARK(100%)</th>
                            <th className="border-2 border-black">{studentsClass && upperPrimary.includes(studentsClass?.name) ? 'GRADE' : 'MARK' }</th>
                            <th className="border-2 border-black">REMARKS</th>
                            <th className="border-2 border-black">TEACHER</th>
                        </tr>
                    </thead>
                    <tbody>
                    {payload?.midMarkData.subdata.map(stat => 
                        <tr key={stat.subjectId} className="border-2 border-purple-400 hover:bg-purple-200 text-sm">
                            <td className="px-1 py-0 border-2 border-black text-sm font-light">{stat.name.toUpperCase()}</td>
                            <td className="border-2 py-0 border-black font-light"> {studentsClass && upperPrimary.includes(studentsClass?.name) ? stat.mark : 100 }</td>
                            <td className="border-2 py-0 border-black font-light">{studentsClass && upperPrimary.includes(studentsClass?.name) ? stringifyAgg(stat.agg) : stat.mark }</td>
                            <td className="border-2 py-0 border-black font-light">{stat.remark}</td>
                            <td className="border-2 py-0 border-black font-light">{ stat.teacher?.split(' ').map(n=>n.charAt(0)) }</td>
                        </tr>
                    )}
                    {studentsClass && upperPrimary.includes(studentsClass?.name) ? 
                    <tr className="border-2 border-black font-extrabold">
                        <td colSpan={3}>
                            AGGREGATES: {payload?.midMarkData.totalAgg}
                        </td>
                        <td colSpan={2}>
                            DIVISION: {payload?.midMarkData.division}
                        </td>
                    </tr>
                    : 
                    <tr className="border-2 border-black text-sm font-extrabold">
                        <td className="p-0">
                            TOTAL: {payload?.midMarkData.totalMarks}
                        </td>
                        <td>
                            POSITION: {payload?.positionMid.position}
                        </td>
                        <td colSpan={3}>
                            OUT OF: {payload?.positionMid.outof}
                        </td>
                    </tr>
                    }
                    </tbody>
                </table>
            </div>
}
            <div className="border-2 border-black px-2 my-2">
                <div className="grid grid-cols-3">
                    {payload?.gradings?.map(g => 
                        <p>{g.marks_from} - {g.marks_to} {studentsClass && upperPrimary.includes(studentsClass?.name) ? stringifyAgg(g.grade) : g.remark }</p>
                    )}
                </div> 

                {studentsClass && upperPrimary.includes(studentsClass?.name) &&
                    <div className="flex justify-around mt-2 font-light">
                        {divisions?.map(d => 
                            <p>{d.aggs_from} - {d.aggs_to}: {d.division}</p>
                        )}
                    </div>
                }
            </div>

            <table className="mt-4 min-w-full font-bold">
                <tr className={`mb-2 align-top border-2 border-black ${studentsClass && upperPrimary.includes(studentsClass?.name) && "h-10"}`}>
                    <th className="text-left border-2 border-black">ClassTeacher's comment:</th> 
                    <td className="text-left border-2 border-black">{payload?.comments?.ct_comm}</td>
                    <td className="block">{payload?.classteacher.toUpperCase()}</td>
                </tr>
                <tr className="border-2 align-top h-16 border-black">
                    <th className="text-left border-2 border-black">HeadTeacher's comment:</th> 
                    <td className="text-left border-2 border-black">{payload?.comments?.ht_comm}</td>
                    <td className="block">{payload?.headteacher.toUpperCase()}</td>
                </tr>
            </table>

            <div className={`mt-2 ${studentsClass && upperPrimary.includes(studentsClass?.name) && "py-4"} flex`}>
                <p>This term ends: <span className="font-bold mr-12">{payload?.term.date_to}</span></p>
                <p>Next term begins: <span className="font-bold">
                    {payload?.term.name?.toUpperCase() === 'THIRD TERM' ? payload.nxtTerm?.date_from?.replace(thisYear.toString(), nextYear.toString()) : payload?.nxtTerm?.date_from}
                </span></p>
            </div>

            <p>REQUIREMENTS: <span className="font-bold italic">{payload?.requirements?.map(r => <span>{r.quantity} {r.name},</span>)}</span></p>
            </section>
        </section>
    )
}

function getTtMk(name: string){
    if(name === 'WRITING' || name === 'DRAWING'){
            return 50;
    }else{
        return 100;
    }
}

function termT (item?: string) {
    switch (item) {
        case "FIRST TERM":
            return "I"
        case "SECOND TERM":
            return "II"
        case "THIRD TERM":
            return "III"
        default:
            return ""
    }
}