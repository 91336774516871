import { useEffect, useState } from "react";
import { AtomicMarkStats, Student, upperPrimary } from "../Interfaces";
import axios from "axios";
import NavBar from "../dashboard/NavBar";
import { useParams } from "react-router-dom";
import stringifyAgg from "../../utils/stringifyGrade";
import { getTypeString, spinner } from "../../utils/customizeAxios";
import { SearchInPlace } from "../../Search";
const apiUrl = process.env.REACT_APP_API_URL;

export default function ClassMarksheet(){
    const {classId} = useParams();
    return( <NavBar chilren={<Page />} classId={classId} />)
}

interface fetchedPayload {
    student: Student;
    markData: AtomicMarkStats;
}

interface metricsTypes {
    subjects: string[];
    items?: [{agg: string, metric: number[]}]
}

function Page(){
    const [students, setStudents] = useState<fetchedPayload[]>([]);
    const [offset, setOffset] = useState(0)
    const [fetchedPayloadLoading, setFetchedPayloadLoading] = useState(false)
    const {classId, type} = useParams();

    const fetchStudents = async () => {
        setFetchedPayloadLoading(true)
        await axios.get(`${apiUrl}/class/studentsWithMarksLimited/${classId}/${type}/${offset}`)
        .then(r => {
            console.log(r.data)
            if(r.data.length < 1) alert("All students have been fetched")
            setStudents([...students, ...r.data].sort((a, b) => b.markData.totalMarks - a.markData.totalMarks));
        }).catch(e => console.error("Failed to fetch: " + e));
        setFetchedPayloadLoading(false)
    };

    const fetchSingleStudent = async (sid: string | number) => {
        setFetchedPayloadLoading(true)
        await axios.get(`${apiUrl}/class/singleStudentWithMarks/${type}/${sid}`)
        .then(r => {
            console.log(r.data)
            if(r.data.length < 1) alert("Student assessment not found")
            setStudents(r.data);
            setOffset(0)
        }).catch(e => console.error("Failed to fetch: " + e));
        setFetchedPayloadLoading(false)
    };

    useEffect(() => {
        fetchStudents();
    }, [classId, type, offset]);
    
    return(
        <>
        {Number(classId)! > 6 &&  <AggregateMetrics />}
        {Number(classId)! > 6 && <DivisionMetrics studentsStats={students} />}
        <div className="flex justify-center p-2">
        <SearchInPlace searchUrl={`${apiUrl}/class/searchStudent/${classId}/`} pholder="Search student" action={fetchSingleStudent}/>
        </div>
        <div className="rounded-lg w-[98%] min-h-[85%] ring-purple-600 mt-2 p-2 mx-auto bg-purple-100 ring-1 overflow-x-auto">
            <table className="w-full table-auto">
                <caption className="caption-top">
                    {students.length > 0 && `${getTypeString(type!).toUpperCase()} ASSESSMENT FOR ${students[0].student.class?.name}`}
                </caption>
                <thead>
                    <tr className="backdrop-blur-md bg-purple-300">
                        <th>No</th>
                        <th className="p-3">NAME</th>
                            {
                                students.length > 0 && students[0].markData.subdata?.map(stat => 
                                    <th colSpan={2} className="px-2" key={stat.subjectId}>{stat.name.slice(0, 4)}</th>    
                                )
                            }
                        <th className="text-red-600">{Number(classId)! > 6 ? "AGG" : "TOTAL"}</th>
                        <th className="text-red-600">{Number(classId)! > 6 ? 'DIVISION' : 'POS'}</th>
                    </tr>
                </thead>
                <tbody>
                {
                fetchedPayloadLoading ?
                    <div className="flex justify-center m-2">{spinner}</div>
                :
                students?.map((student, i) => 
                    <StudentStats key={student.student.id} stdnt={student} no={i + 1} />
                
                )}
                </tbody>
            </table>
            {
                fetchedPayloadLoading ?
                <div className="flex justify-center m-2">{spinner}</div>
                :
                <button onClick={() => setOffset(offset + 20)} className="inputstyle m-2 w-full print:hidden">More</button>
            }
            <div className="flex justify-center m-2">{students.length} results</div>
        </div>

        </>
    );
}


/************************************************************************************************* */
interface getProps{
    no: number;
    stdnt: fetchedPayload;
}

function StudentStats({stdnt, no}: getProps){
    const stdntclass = stdnt.student.class?.name
    return(
        <tr className="border-b-2 border-purple-400 hover:bg-purple-300">
            <td>{no}</td>
            <td className="border-2 border-purple-400">{stdnt.student.name}</td>
            {stdnt.markData.subdata?.map(item => 
            <>
                <td key={item.subjectId} className={`${!item.mark && 'text-red-600'} border-l-2 border-purple-400`}>
                    {item.mark ? item.mark : ''}
                </td>
                <td className="border-r-2 border-purple-400">
                    {upperPrimary.includes(stdntclass!) && stringifyAgg(item.agg)}
                </td>
            </>
            )}
            <td className="text-red-600 border-r-2 border-purple-400">{upperPrimary.includes(stdntclass!) ? stdnt.markData.totalAgg : stdnt.markData.totalMarks}</td>
            {upperPrimary.includes(stdntclass!) ?
                <td className="text-red-600 border-r-2 border-purple-400">{stdnt.markData.division ? stdnt.markData.division : 'N/A'}</td>
            :
                <td className="text-red-600 border-r-2 border-purple-400">{stdnt.markData.position ? stdnt.markData.position : 'N/A'}</td>
            }
        </tr>

    );
}

/************************************************************************************************ */
function AggregateMetrics(){
    const [classMetrics, setClassMetrics] = useState<metricsTypes>({subjects: []});
    const [isLoading, setIsLoading] = useState(false);
    const {classId, type} = useParams();

    const fetchClassMetrics = async () => {
        setIsLoading(true)
        await axios.get(`${apiUrl}/class/gradingPerSubject/${classId}/${type}`)
        .then(r => {
            setClassMetrics(r.data);
        }).catch(e => console.error("Failed to fetch: " + e));
        setIsLoading(false)
    }

    useEffect(() => {
        //const timer = setTimeout(() => {
            fetchClassMetrics();
        // }, 5000);
        // return () => clearTimeout(timer);
    }, [classId, type]);
    
    return(
        <div className="rounded-lg w-[98%] min-h-[85%] ring-purple-600 mt-2 p-2 mx-auto bg-purple-100 ring-1 overflow-x-auto">
        {isLoading ?
        <div className="flex justify-center">{spinner}</div>
        :
        <table className="w-full table-auto">
            <thead>
                <tr className="backdrop-blur-md text-left bg-purple-300">
                    <th className="p-3">Aggregates</th>
                        {
                            classMetrics.subjects.map(subj =>
                                <th key={subj}>{subj.slice(0,4)}</th>    
                            )
                        }
                </tr>
            </thead>
            <tbody>
                {classMetrics.items?.map(item => 
                    <tr>
                        <th className="text-left">{item.agg}</th>
                        {item.metric.map(metric => 
                            <td key={Math.random()}>{metric}</td>    
                        )}
                    </tr>
                )}
            </tbody>
        </table>
        }
        </div>
    );
}

/****************************************************************************************************** */
interface DivisionMetricInterface {
    division: string;
    count: number;
}
function DivisionMetrics({studentsStats}: {studentsStats: fetchedPayload[]}){
    const [metrics, setMetrics] = useState<DivisionMetricInterface[]>([])

    useEffect(() => {
        if (!studentsStats || studentsStats.length === 0) return;
    
        let divs = { I: 0, II: 0, III: 0, IV: 0, U: 0 };
    
        studentsStats.forEach(item => {
            const div = item.markData.division;
            switch (div) {
                case 'I':
                    divs.I++;
                    break;
                case 'II':
                    divs.II++;
                    break;
                case 'III':
                    divs.III++;
                    break;
                case 'IV':
                    divs.IV++;
                    break;
                default:
                    divs.U++;
                    break;
            }
        });
    
        setMetrics((prevMetrics) => [
            ...prevMetrics.filter(m => !divs.hasOwnProperty(m.division)),
            ...Object.entries(divs).map(([division, count]) => ({ division, count }))
        ]);
    }, [studentsStats]);
    

    return(
        <div className="rounded-lg w-[98%] min-h-[85%] ring-purple-600 mt-2 p-2 mx-auto bg-purple-100 ring-1">
        <table className="w-full">
                <thead>
                    <tr className="backdrop-blur-md text-left bg-purple-300">
                        {
                            metrics.map(metric =>
                                <th key={metric.division}>{metric.division}</th>    
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {metrics.map(metric => 
                            <td key={metric.division}>{metric.count}</td>    
                        )}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}