import { FormEvent, useState } from "react"
import { api } from "../../utils/customizeAxios"

export default function CustomComment({studentId, currentComment}: {studentId: number, currentComment: string}) {
    const [comment, setComment] = useState(currentComment)
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        await api.post('/student/comment/' + studentId, {comment: comment})
        .then(r => {
            if(r.data.message){
                alert(r.data.message)
            } else {
                alert("Success, Please refresh to see the change")
            }
        }).catch(e =>  {
            if(e.response.data && e.response.data.message){
                alert(e.response.data.message)
            }else {
                alert("Unknown error")
            }
        })
    }
    return(
        <form onSubmit={e => handleSubmit(e)}>
            <input type="text" name="comment" 
                placeholder="Custom classteacher comment" 
                className="inputstyle mx-2"
                value={comment} onChange={(e) => setComment(e.target.value)} />

            <input type="submit" value="Submit" className="inputstyle" />
        </form>
    )
}